export function getDeeplink(route) {
  return "purplle.com://promotion?url=" + location.origin + route;
}
export function generateRandomString(length: number = 18, includeNumbers: boolean = true) {
  let result = "";
  const characters = `ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz${includeNumbers?'0123456789':''}`;
  const charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
